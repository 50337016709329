/* 
orange
ff7f11

red
ff1b1c 

blue
429aae

light yellow
f5ee9e

off-white
fdffff 

dark-green contrast
042a2b
*/
a {
  text-decoration: none !important;
  cursor: default;
}

p {
  cursor: default;
}

h1 {
  -webkit-text-decoration: outline 2px #042a2b;
          text-decoration: outline 2px #042a2b;
  font-weight: bolder;
}

.appStyle {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  background-color: #fdffff;
  font-family: 'Fredoka', Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: .2s;
  transition: .2s;
}

.arrayText {
  text-align: center;
}

.allComponentsWrapper {
  max-width: 1700px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  margin: 0%;
}

.bannerText {
  font-weight: bolder;
  color: #429aae;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.bannerContainer {
  margin: 50px 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  width: 100%;
  max-height: 80vh;
  top: 100px;
}

.card {
  margin-top: 20px;
  height: 120px;
  width: 100%;
  text-align: center;
}

.carouselImgDiv {
  width: 1600;
  max-height: 800px;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  -o-object-position: bottom !important;
     object-position: bottom !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.footer {
  bottom: 0;
  position: fixed;
}

.footerIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.footerIconBackground {
  border-radius: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px 0px 5px 5px;
  padding: 2px 5px 5px 6px;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #429aae;
  background-color: #fdffff;
  -webkit-transition: .3s;
  transition: .3s;
}

.footerIconBackground:hover {
  background-color: #f5ee9e;
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-transform: translateY(-2px) scale(1.1);
          transform: translateY(-2px) scale(1.1);
}

.header {
  font-weight: bolder;
  letter-spacing: 2px;
  cursor: default;
  color: #ff7f11;
  text-shadow: 1px 1px #042a2b;
}

.form {
  background-color: white;
}

.geoButton {
  cursor: pointer;
}

.inputRow {
  width: 100%;
  border: none;
  font-family: 'Fredoka', Geneva, Tahoma, sans-serif;
  color: #ff7f11;
  font-weight: bold;
}

.inputField {
  border: 2px solid #429aae;
  border-radius: 10px;
  width: 100%;
  color: #429aae;
  height: 40px;
}

.inputField:focus {
  outline-color: #ff7f11;
}

textArea {
  height: 200px !important;
}

.submit {
  width: 100px;
  text-align: center;
  border-radius: 10px;
  float: right;
  color: #429aae;
  text-shadow: none;
  -webkit-transition: .3s;
  transition: .3s;
}

.submit:hover {
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-transform: scale(1.1) translateY(-2px);
          transform: scale(1.1) translateY(-2px);
  background-color: #f5ee9e;
}

.mainHeader {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: bolder;
  letter-spacing: 2px;
  cursor: default;
  -webkit-transition: .3s;
  transition: .3s;
  color: #ff7f11;
}

.mainHeaderDesktop {
  position: absolute;
  text-align: left;
  margin-left: 2vw;
  font-size: 60px;
  padding-top: 10px;
  -webkit-transition: .3s;
  transition: .3s;
  opacity: 100%;
}

.mainHeaderMobile {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 50px;
  -webkit-transition: .3s;
  transition: .3s;
}

.mugClubPic {
  background-image: url("../Img/About/mug.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: block;
}

.mugClubPicSm {
  height: 50%;
  position: relative;
}

.modalButton {
  height: 35px;
  top: 20px;
  right: 8vw;
  z-index: 1000;
  background-color: #fdffff;
  border-radius: 10px;
  font-weight: bolder;
  color: #429aae;
  border: 3px solid #429aae;
  -webkit-transition: .3s;
  transition: .3s;
}

.modalButton:hover {
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-transform: translateY(-2px) scale(1.1);
          transform: translateY(-2px) scale(1.1);
  background-color: #f5ee9e;
}

.menuStyles {
  background-color: #2b3a67;
  padding: 30px;
  border-radius: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin: 0%;
  cursor: default;
}

.modalCloseButton {
  -webkit-transition: .3s;
  transition: .3s;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.modalCloseButton:hover {
  background-color: #f5ee9e;
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-transform: scale(1.1) translateY(-2px);
          transform: scale(1.1) translateY(-2px);
}

.mobileNavbarButton {
  position: fixed;
  height: 35px;
  top: 20px;
  right: 8vw;
  z-index: 1000;
  background-color: #fdffff;
  border-radius: 20px;
  border: 1px #429aae;
}

.mobileNavbarButton:hover {
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-transform: translateY(-2px) scale(1.1);
          transform: translateY(-2px) scale(1.1);
}

.mobileDropdownButtonGroup {
  text-align: center;
  -ms-flex-line-pack: center;
      align-content: center;
  height: 100px;
  padding: 0px;
}

.navButton {
  cursor: pointer;
  color: #429aae;
  margin-left: 5vw;
  font-size: 20px;
  font-weight: bolder;
  font-family: 'Fredoka', Geneva, Tahoma, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: .3s;
  transition: .3s;
}

.navButton:hover {
  -webkit-transition: .3s;
  transition: .3s;
  color: #ff7f11;
  -webkit-transform: translateY(-2px) scale(1.1);
          transform: translateY(-2px) scale(1.1);
}

.photoTileContainer {
  margin-top: 30vh;
  margin-bottom: 10vh;
}

.photoTile {
  border-radius: 10px;
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.photoTile:hover {
  -webkit-transition: .2s linear;
  transition: .2s linear;
  -webkit-transform: translateY(-1px) scale(1.01);
          transform: translateY(-1px) scale(1.01);
}

.photoTileLg {
  width: 25%;
  margin: 7px 7px 7px 7px;
}

.photoTileBBread {
  background-image: url("../Img/About/tile Bbread.png");
}

.photoTileOmelet {
  background-image: url("../Img//About/tile breakfastOmelet.png");
}

.photoTileFront {
  background-image: url("../Img//About/tile front door.png");
}

.photoTileBrocSoup {
  background-image: url("../Img//About/tile brocsoup.png");
}

.photoTileLobby {
  background-image: url("../Img//About/tile lobby.png");
}

.photoTileBanner {
  background-image: url("../Img//About/tile banner.png");
}

.photoTileChili {
  background-image: url("../Img//About/tile chili.png");
}

.photoTile1year {
  background-image: url("../Img//About/1 year.png");
}

.photoTileSm {
  width: 50%;
  margin: 5px;
}

.photoTileArrayRow {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.responsiveMap {
  overflow: hidden;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.responsiveMap iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.subHeader {
  font-size: 50px;
}

.subBanner {
  width: 100%;
  height: 600px;
  background-color: #3898ad;
  border: 8px solid #042a2b;
  border-radius: 20px;
  margin-top: 40px;
  overflow: hidden;
  margin-bottom: 50px;
}

.subBannerText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding: 210px 30px 0px 30px;
  height: 600px;
}

.subBannerHalf1 {
  display: inline-block;
  background-color: #429aae;
}

.subBannerHalf1Lg {
  display: inline-block;
  height: 100%;
  width: 50%;
  vertical-align: top;
}

.subBannerHalf1Sm {
  display: block;
  height: 50%;
  width: 100%;
}

.subBannerHalf2 {
  background-color: #f5ee9e;
  position: relative;
}

.subBannerHalf2Lg {
  display: inline-block;
  height: 100%;
  width: 50%;
}

.subBannerHalf2Sm {
  display: block;
  height: 50%;
  width: 100%;
}

.tileText {
  position: absolute;
  text-align: center;
  color: #ff7f11;
  text-shadow: 2px 2px #474747;
}

.welcomeCard {
  background-color: transparent;
  color: #ff7f11;
  border-radius: 20px;
  height: 125px;
  width: 100%;
  text-align: center;
}

.welcomeCardHeader {
  font-size: 30px !important;
}

.welcomeCardImg {
  width: 40%;
  aspect-ratio: 1;
  border-radius: 100;
  overflow: hidden;
  position: relative;
}

.welcomeCardRow {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.welcomeCarousel {
  width: 100%;
  position: relative;
  max-height: 80vh;
}

/*required so the images in the carousel align with the carousel component itself*/
.welcomeCarouselImg {
  width: 100%;
  height: 100%;
}

/*--Media Queries------------------------------------------------------------------------------*/
/*bootstrap extra-large breakpoint*/
@media (max-width: 1200px) {
  .fadesAtXLViewport {
    display: none;
  }
}

/*bootstrap large breakpoint*/
@media (max-width: 992px) {
  .bannerText {
    text-align: center;
    padding-top: 1vh;
  }
  .bannerContainer {
    margin: 0;
  }
}

/*bootstrap medium breakpoint*/
@media (max-width: 768px) {
  .mapStyle {
    text-align: center;
  }
  .menuStyles {
    margin-left: 10px;
    margin-right: 10px;
  }
  .welcomeCard {
    max-width: 450px;
  }
  .subBannerText {
    padding-top: 60px;
  }
  .subHeader {
    font-size: 30px;
  }
}

/*bootstrap extra-small breakpoint*/
@media (max-width: 576px) {
  .card {
    height: 100px;
  }
  .navButton {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0;
    font-size: 15px;
    margin: 0px 2px 0px 2px;
  }
  .welcomeCard {
    max-height: 400px;
  }
}

@media (max-width: 510px) {
  .mainHeaderMobile {
    text-align: left;
    margin-left: 2vw;
  }
}

@media (max-width: 410px) {
  .mainHeaderMobile {
    font-size: 30px;
    padding-top: 10px;
  }
  .mobileNavbarButton {
    top: 10px;
  }
  .navButton {
    margin-top: 20px;
    font-size: 12px;
  }
  .mobileDropdownButtonGroup.offcanvas-body {
    padding: 0px;
  }
}
